<template>
  <v-container fluid class="mb-5 mt-3">
    <v-row class="m-2">
      <v-col cols="12" md="12" lg="12" sm="12">
        <v-row class="mt-1">
          <v-col cols="12" md="4" lg="4" sm="4">
            <v-text-field
              label="NAMA PASIEN 🔎"
              v-model="search"
              outlined
              dense
              @change="getOrderResep"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" lg="4" sm="4">
            <v-select
              :items="['', 'NON RACIKAN', 'RACIKAN']"
              label="JENIS RESEP 🐍"
              dense
              v-model="is_racikan"
              @change="getOrderResep"
              outlined
            ></v-select>
            <!-- <label for="">Jenis Resep 🐍</label>
            <select
              v-model="is_racikan"
              class="form-control form-control-md"
              id=""
            >
              <option value="">-- Cari Item --</option>
              <option value="NON">NON RACIKAN</option>
              <option value="RACIKAN">RACIKAN</option>
            </select> -->
          </v-col>
          <v-col cols="12" md="4" lg="4" sm="4">
            <v-text-field
              v-model="tgl_registration"
              label="TANGGAL ORDER"
              prepend-icon="mdi-calendar"
              type="date"
              @change="getOrderResep"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card>
          <div>
            <v-simple-table fixed-header class="p-2">
              <thead>
                <tr>
                  <th
                    width="10%"
                    style="text-align: center; vertical-align: middle"
                  >
                    <b>AKSI 🛠</b>
                  </th>
                  <th
                    width="10%"
                    style="text-align: center; vertical-align: middle"
                  >
                    <b>No. RM 🔑</b>
                  </th>
                  <th
                    width="25%"
                    style="text-align: center; vertical-align: middle"
                  >
                    NAMA PASIEN 👴
                  </th>

                  <th
                    width="15%"
                    style="text-align: center; vertical-align: middle"
                  >
                    TANGGAL ORDER 📅
                  </th>
                  <th
                    width="20%"
                    style="text-align: center; vertical-align: middle"
                  >
                    STATUS OBAT 📕
                  </th>
                  <th
                    width="25%"
                    style="text-align: center; vertical-align: middle"
                  >
                    STATUS ORDERAN 💊
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id_order_resep">
                  <td style="text-align: center; vertical-align: middle">
                    <v-btn
                      x-small
                      fab
                      dark
                      color="blue"
                      @click="liatDetailPasien(item)"
                    >
                      <v-icon>mdi-pencil</v-icon> </v-btn
                    >&nbsp;<v-btn
                      x-small
                      fab
                      dark
                      color="primary"
                      @click="ItemTindakan(item)"
                    >
                      <v-icon>mdi-doctor</v-icon>
                    </v-btn>
                  </td>
                  <td style="text-align: center; vertical-align: middle">
                    {{ item.medrec_no }}
                  </td>
                  <td style="text-align: center; vertical-align: middle">
                    <b>{{ item.patient_name }}</b>
                  </td>

                  <td style="text-align: center; vertical-align: middle">
                    {{ item.tgl_order }}
                  </td>
                  <td style="text-align: center; vertical-align: middle">
                    {{ item.status }}
                  </td>
                  <td style="text-align: center; vertical-align: middle">
                    <div v-if="item.is_racikan == '1'">
                      <v-chip color="orange darken-1" dark>
                        <b>
                          {{ item.isRacikanResults }} - {{ item.no_antrian }}</b
                        >
                      </v-chip>
                    </div>
                    <div v-else>
                      <v-chip color="light-green darken-1" dark>
                        <b>
                          {{ item.isRacikanResults }} - {{ item.no_antrian }}</b
                        >
                      </v-chip>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div class="row justify-content-center mt-3">
              <div class="col-md-3 col-lg-3 col-sm-3">
                <nav aria-label="Page navigation example">
                  <ul class="pagination btn-md">
                    <li class="page-item">
                      <a class="page-link" @click="initPermintaanPreviosPage"
                        >Previous</a
                      >
                    </li>
                    <li class="page-item">
                      <a
                        class="page-link"
                        v-html="permintaan_pagination_container"
                      ></a>
                    </li>
                    <li class="page-item">
                      <a
                        class="page-link"
                        @click="initPermintaanNextPage"
                        href="#"
                        >Next</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialogListDetailObat" persistent max-width="650">
      <v-card>
        <v-card-title class="headline"
          >E-Resep Detail
          <v-btn text @click="tutupDialog" color="deep-purple accent-4">
            Tutup
          </v-btn>
        </v-card-title>
        <v-card-text v-if="listObatDetail.item_order">
          <h5 class="text-primary"><b>Order Non Racikan</b>💊</h5>
          <ul
            class="list-group mr-4"
            v-for="(itemNonRacikan, indexNonRacikan) of listObatDetail
              .item_order.data"
            :key="indexNonRacikan"
          >
            <li class="list-group-item mt-1">
              💊 {{ itemNonRacikan.obat_name }}
              <b
                >({{ itemNonRacikan.jumlahObat }}&nbsp;{{
                  itemNonRacikan.uom
                }})</b
              ><br />
              <b>🥃 {{ itemNonRacikan.dosis }}</b
              >,<br />
              <b>⏰ {{ itemNonRacikan.signa }}</b>
            </li>
          </ul>
        </v-card-text>
        <v-card-text v-if="listObatDetail.item_order_racikan">
          <h5 class="text-warning"><b>ORDER RACIKAN</b> 💊💊</h5>
          <ul
            class="list-group mr-4"
            v-for="(itemRacikan, indexRacikan) of listObatDetail
              .item_order_racikan.dataRacikan"
            :key="indexRacikan"
          >
            <li class="list-group-item mt-1">
              {{ itemRacikan.id_racikan }}
              <b>({{ itemRacikan.signa }}&nbsp;{{ itemRacikan.dosis }})</b
              ><br />
              <ul
                v-for="(
                  itemRacikanObat, indexRacikanDetailObat
                ) of listObatDetail.item_order_racikan.data"
                :key="indexRacikanDetailObat"
              >
                <li v-if="itemRacikanObat.id_racikan == itemRacikan.id_racikan">
                  💊 <b>{{ itemRacikanObat.obat_name }}</b> ({{
                    itemRacikanObat.qty
                  }}&nbsp;{{ itemRacikanObat.uom }})
                </li>
              </ul>
            </li>
          </ul>
        </v-card-text>
        <v-card-text v-if="listObatDetail.item_bhp">
          <h5 class="text-danger"><b>ORDER BHP 📌</b></h5>
          <ul
            class="list-group mr-4"
            v-for="(itemBhp, indexBhp) of listObatDetail.item_bhp.data"
            :key="indexBhp"
          >
            <li class="list-group-item mt-1">📌 {{ itemBhp.obat_name }}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="tutupDialog()" color="deep-purple accent-4">
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog tindakan -->
    <v-dialog v-model="showDialogAcc" persistent max-width="490">
      <v-card>
        <v-card-title class="headline">👩‍⚕️ BHP </v-card-title>
        <v-card-subtitle>
          <v-btn text @click="tutupDialogAcc()" color="deep-purple accent-4">
            Tutup
          </v-btn>
        </v-card-subtitle>

        <v-card-text v-if="listObatDetail.item_bhp">
          <table class="table table-bordered table-sm" width="100%">
            <thead>
              <tr>
                <th style="text-align: center; vertical-align: middle">
                  Nama BHP
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(itemBhpOrder, indexBhpOrder) of listObatDetail.item_bhp
                  .data"
                :key="indexBhpOrder"
              >
                <td style="text-align: center; vertical-align: middle">
                  {{ itemBhpOrder.obat_name }}
                  <br />
                  <button
                    class="btn btn-outline-danger btn-block btn-sm mt-1"
                    @click="hapusItemBhp(indexBhpOrder)"
                    :disabled="listObatDetail.status != 'ORDER'"
                  >
                    ❌ Hapus Item
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-text>
          <table>
            <tr>
              <td style="text-align: center; vertical-align: middle">
                <b>CATATAN</b> : {{ listObatDetail.catatan_tambahan }}
              </td>
            </tr>
          </table>
        </v-card-text>
        <v-card-text class="mr-4">
          <v-select
            :items="listFarmasi"
            label="Antrian Farmasi"
            dense
            :disabled="listObatDetail.status != 'ORDER'"
            v-model="listObatDetail.tujuan"
            outlined
          ></v-select>
          <!-- {{ listOrder }} -->
          <v-autocomplete
            return-object
            outlined
            class="mt-4"
            dense
            item-text="text"
            :disabled="listObatDetail.status != 'ORDER'"
            item-value="id"
            v-model="tindakan"
            :items="getTindakan"
            :search-input.sync="searchTindakan"
            @change="addTindakan()"
            label="🔎 NAMA ITEM"
            placeholder="CARI ITEM BHP "
          ></v-autocomplete>
          <!-- {{ listOrder }} -->
          <ul
            class="list-group"
            v-for="(itemBhp, indexBhp) of listOrder"
            :key="indexBhp"
          >
            <li class="list-group-item mt-1">
              🟦&nbsp;{{ itemBhp.obat_name }}
              <b>({{ itemBhp.jumlahObat }}&nbsp;{{ itemBhp.uom }})</b><br />
              <button
                class="btn btn-danger btn-block btn-sm mt-1"
                @click="hapusItem(indexBhp)"
              >
                ❌ Hapus Item
              </button>
            </li>
          </ul>
          <v-textarea
            outlined
            class="mt-3"
            name="input-7-4"
            :disabled="listObatDetail.status != 'ORDER'"
            v-model="listObatDetail.catatan_tambahan"
            label="Catatan Tambahan Dari Perawat 📋"
          ></v-textarea>
          <v-divider></v-divider>
          <ckeditor
            :editor="editor"
            v-model="editorData"
            :config="editorConfig"
          ></ckeditor>
        </v-card-text>

        <v-card-actions>
          <button
            @click="simpanBhp"
            type="button"
            :disabled="listObatDetail.status != 'ORDER'"
            class="btn btn-primary btn-block text-white btn-sm mt-1"
          >
            🔐 SIMPAN BHP</button
          >&nbsp;<button
            @click="kirimResep"
            type="button"
            :disabled="listObatDetail.status != 'ORDER'"
            class="btn btn-danger btn-block text-white btn-sm mt-1"
          >
            📤 KIRIM RESEP
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
export default {
  data () {
    return {
      search: '',
      tgl_registration: '',
      is_racikan: '',
      listOrder: [],
      catatanTambahanPerawat: '',
      itemTindakan: [],
      id_order: 0,
      showDialogListDetailObat: false,
      showDialogAcc: false,
      permintaans_page: 1,
      permintaans_per_page: 50,
      permintaan_pagination_container: '0-0 of 0',
      permintaans_total: '',
      items: '',
      item_bhp_list: '',
      tindakan: '',
      searchTindakan: '',
      listFarmasi: [],
      listObatDetail: {
        item_bhp: {
          data: [],
          catatanTambahanPerawat: '',
          tujuan: ''

        }
      },
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      },
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false
    }
  },
  created () {
    this.getOrderResep()
    this.jamSekarang()
    this.getDataFarmasi()
  },
  methods: {
    addTindakan () {
      this.id_order = this.id_order + 1

      var self = this
      this.listOrder.forEach((val, index) => {
        if (val.obat_id === this.tindakan.value) {
          self.listOrder.splice(index, 1)
          return false
        }
      })

      // eslint-disable-next-line no-unused-vars
      var pajak = (this.normalisasi(this.tindakan.unit_price) * 1 * 11) / 100

      this.listOrder.push({
        no: this.id_order,
        item_id: this.tindakan.value,
        obat_id: this.tindakan.value,
        item_name: this.tindakan.text,
        obat_name: this.tindakan.text,
        uom_id: this.tindakan.uom_id,
        uom: this.tindakan.uom,
        unit_id: this.tindakan.unit_id,
        pay_by_id: this.tindakan.pay_by_id,
        unit_price: this.tindakan.unit_price,
        tax1_amount: pajak,
        jumlahObat: 1,
        signa: 'OK',
        dosis: 'OK',
        pay_by_amount: this.tindakan.jumlahDitanggung,
        self_amount: this.tindakan.jumlahSendiri,
        sales_tax1_id: 0,
        uom_conversion: 0,
        jumlah: this.tindakan.jumlah,
        jenis: this.tindakan.jenis
      })

      this.tindakan = []

      // console.log(this.order)
    },
    initPermintaanPreviosPage () {
      var currentPage = parseInt(this.permintaans_page)
      if (currentPage > 1) {
        currentPage = currentPage - 1
      } else {
        currentPage = 1
      }
      this.permintaans_page = currentPage
      this.getOrderResep()
    },
    jamSekarang () {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      var yyyy = today.getFullYear()

      // today = mm + '/' + dd + '/' + yyyy;
      today = yyyy + '-' + mm + '-' + dd
      // return date;
      this.tgl_registration = today
      console.log(this.tgl_registration)
    },
    initPermintaanNextPage () {
      let no = 1
      var currentPage = parseInt(this.permintaans_page)
      var totalPages = parseInt(this.permintaans_total)

      console.log(currentPage)
      console.log(totalPages)
      no = currentPage + 1

      if (currentPage === totalPages) {
        no = currentPage
      }
      // $('#permintaans_page').val(no)
      this.permintaans_page = no
      this.getOrderResep()
    },
    normalisasi (angka) {
      var temp = angka.replace(/[^,\d]/g, '').toString()
      const text = temp
      let result = text.replace(',', '.')
      result = parseFloat(result)
      // console.log(result);
      return result
    },
    getOrderResep () {
      const data = {
        tgl_registration: this.tgl_registration,
        pg: this.permintaans_page,
        ppg: this.permintaans_per_page,
        pls: this.search,
        created_by: this.$route.query.sub_unit_id,
        is_racikan: this.is_racikan,
        items: []
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 + 'pasien/history-order-resep',
        data,
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, results } = response
          if (con) {
            // console.log("111111");
            setTimeout(() => {
              const pagination = results.pagination
              this.permintaan_pagination_container = pagination
              this.permintaans_total = results.total_pages
              this.items = results.list
              // this.show = false;
            }, 150)
          } else {
            console.log('111')
            errorMsg('Data Tidak Ditemukan')
          }
        },
        'JSON'
      )
    },
    liatDetailPasien (e) {
      console.log(e)
      this.showDialogListDetailObat = true
      this.listObatDetail = e
    },
    ItemTindakan (e) {
      console.log(e)
      this.showDialogAcc = true
      this.listObatDetail = e
    },
    tutupDialog () {
      this.showDialogListDetailObat = false
      this.getOrderResep()
    },
    tutupDialogAcc () {
      this.showDialogAcc = false
      this.getOrderResep()
      this.listOrder = []
    },
    hapusItem (index) {
      this.listOrder.splice(index, 1)
    },
    hapusItemBhp (index) {
      this.listObatDetail.item_bhp.data.splice(index, 1)
    },
    simpanBhp () {
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Membuat Orderan BHP??',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Buat Orderan BHP!',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          const firebasePasien = {
            no_rm: this.$route.query.no_rm,
            folio_id: this.$route.query.folio_id,
            reg_id: this.$route.query.registration_id,
            sub_unit_id: this.$route.query.sub_unit_id
          }

          const data = {
            data: this.listOrder,
            identitas: firebasePasien,
            dataOld: this.listObatDetail.item_bhp
              ? this.listObatDetail.item_bhp.data
              : [],
            catatan_tambahan: this.listObatDetail.catatan_tambahan
          }
          // eslint-disable-next-line no-undef
          $.post(
            baseUrlV2 + 'pasien/simpan-bhp',
            data,
            (response) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = response
              if (con) {
                successMsg(msg)
                // this.listObat = []
                // this.banyakRacikan = []
              } else {
                errorMsg(msg + ' ' + JSON.parse(results))
              }
            },
            'JSON'
          )
          console.log(data)
        } else {
          errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },
    kirimResep () {
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Mengirim E-Resep Ke Unit Farmasi,',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Kirim Orderan Farmasi',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          var data = {
            folio_id: this.$route.query.folio_id,
            id_order_resep: this.listObatDetail.id_order_resep,
            farmasi: this.listObatDetail.tujuan
          }
          // eslint-disable-next-line no-undef
          $.post(
            baseUrlV2 + 'pasien/pilih-resep',
            data,
            (res) => {
              // eslint-disable-next-line no-undef
              const { con, msg, results } = res
              if (con) {
                console.log(msg)
                successMsg(msg)
                setTimeout(() => {
                  this.showDialogAcc = false
                  this.getOrderResep()
                }, 100)
              } else {
                console.log(results)
                errorMsg(msg)
              }
            },
            'JSON'
          )
          console.log(data)
        } else {
          errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },

    getDataFarmasi () {
      // eslint-disable-next-line no-undef
      $.get(baseUrlV2 + 'poliklinik/only-farmasi', (res) => {
        // eslint-disable-next-line no-undef, no-unused-vars
        const { con, msg, results } = res
        if (con) {
          this.listFarmasi = results
        } else {
          this.listFarmasi = []
        }
      }, 'JSON')
    }
  },
  computed: {
    getTindakan () {
      return this.itemTindakan.map((v) => ({
        text: v.text,
        value: v.id,
        uom_id: v.uom_id,
        uom: v.uom,
        unit_id: v.unit_id,
        pay_by_id: v.pay_by_id,
        jumlahDitanggung: v.jumlahDitanggung,
        jumlahSendiri: v.jumlahSendiri,
        sales_tax1_id: v.sales_tax1_id,
        unit_price: v.jumlah,
        jumlah: v.jumlah,
        jenis: v.jenis
      }))
    }
  },
  watch: {
    searchTindakan (val) {
      // console.log(val)
      if (val && (!this.tindakan || this.tindakan.text !== val)) {
        // eslint-disable-next-line no-undef
        $.get(
          baseUrlV2 +
            'poliklinik/cari-item-bhp?q=' +
            val +
            '&sub=' +
            this.$route.query.sub_unit_id +
            '&folio_id=' +
            this.$route.query.folio_id,
          (res) => {
            this.itemTindakan = res.results
          },
          'JSON'
        )
      }
    },

    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    }
  }
}
</script>
